import { Controller } from '@hotwired/stimulus';

// Controller for detecting swipe in lightbox and trigger next or previous
// button to change image. Only works on screens with touch.

export default class extends Controller {
  checkForSwipe() {
    this.lightboxArea = document.getElementById('lightbox-skeleton');
    const lightboxContentArea = this.lightboxArea.querySelector(
      '.lightbox-content-wrap',
    );
    lightboxContentArea.addEventListener(
      'touchstart',
      this.handleTouchStart,
      false,
    );
    lightboxContentArea.addEventListener(
      'touchmove',
      this.handleTouchMove,
      false,
    );

    this.xDown = null;
    this.yDown = null;
  }

  handleTouchStart = (e) => {
    const firstTouch = this.getTouches(e)[0];
    this.xDown = firstTouch.clientX;
    this.yDown = firstTouch.clientY;

    this.prevButton = this.lightboxArea.querySelector('.lightbox-nav--prev');
    this.nextButton = this.lightboxArea.querySelector('.lightbox-nav--next');
  };

  handleTouchMove = (e) => {
    if (!this.xDown || !this.yDown) {
      return;
    }

    const xUp = e.touches[0].clientX;
    const yUp = e.touches[0].clientY;

    const xDiff = this.xDown - xUp;
    const yDiff = this.yDown - yUp;

    if (Math.abs(xDiff) > Math.abs(yDiff)) {
      // Swipe right
      if (xDiff > 0) {
        setTimeout(() => {
          this.nextButton.click();
        }, 500);
        // Swipe left
      } else {
        setTimeout(() => {
          this.prevButton.click();
        }, 500);
      }
    }
    this.xDown = null;
    this.yDown = null;
  };

  getTouches(e) {
    return e.touches;
  }
}
