import { Controller } from '@hotwired/stimulus';

import { getWindowWidth, parseHTML } from 'js-common';

const VIDEO_SMALL_THRESHOLD = 800;

// Controller for video, loading the relevant size
export default class extends Controller {
  initialize() {
    const videoElement = this.element;
    const dataSrc =
      getWindowWidth() <= VIDEO_SMALL_THRESHOLD ? 'small' : 'large';

    const source = parseHTML(
      `<source type="video/mp4" src="${this.data.get(dataSrc)}">`,
    );
    videoElement.append(source);

    // Only autoplay video if user not has reduced-motion turned on.
    const hasReducedMotion = window.matchMedia(
      '(prefers-reduced-motion: reduce)',
    );
    if (!hasReducedMotion.matches) {
      videoElement.setAttribute('autoplay', true);
    }
  }
}
