import { Controller } from '@hotwired/stimulus';

const hiddenClass = 'hidden';
const selectedClass = 'filter-box--selected';
const remove = 'remove';
const add = 'add';

export default class extends Controller {
  static targets = [
    'select',
    'item',
    'residentialItem',
    'commercialItem',
    'allBox',
    'residentialBox',
    'commercialBox',
  ];

  setClass(residential, commercial) {
    this.residentialItemTargets.forEach((item) => {
      item.classList[residential](hiddenClass);
    });
    this.commercialItemTargets.forEach((item) => {
      item.classList[commercial](hiddenClass);
    });
  }

  select(event) {
    const list = event.target.value;
    switch (list) {
      case 'all':
        this.allBoxTarget.classList.add(selectedClass);
        this.residentialBoxTarget.classList.remove(selectedClass);
        this.commercialBoxTarget.classList.remove(selectedClass);
        this.setClass(remove, remove);
        break;
      case 'residential':
        this.allBoxTarget.classList.remove(selectedClass);
        this.residentialBoxTarget.classList.add(selectedClass);
        this.commercialBoxTarget.classList.remove(selectedClass);
        this.setClass(remove, add);
        break;
      case 'commercial':
        this.allBoxTarget.classList.remove(selectedClass);
        this.residentialBoxTarget.classList.remove(selectedClass);
        this.commercialBoxTarget.classList.add(selectedClass);
        this.setClass(add, remove);
        break;
      default:
      // No default
    }
  }
}
