import { Controller } from '@hotwired/stimulus';

// Controller for showing more images in images section
export default class extends Controller {
  static targets = ['images', 'gridImages'];

  load(event) {
    const images = this.imagesTarget;
    const gridImages = this.gridImagesTarget;
    gridImages.style.opacity = '0';
    gridImages.style.height = '0';
    gridImages.style.visibility = 'hidden';

    const imagesItems = images.querySelectorAll('.estate-description-image');
    imagesItems.forEach((element) => {
      element.style.transform = 'scale(1)';
    });

    images.style.height = 'auto';
    images.style.visibility = 'visible';
    images.style.opacity = '1';
  }
}
