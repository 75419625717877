import { Controller } from '@hotwired/stimulus';
import microModal from 'micromodal';
import lozad from 'lozad';

const observer = lozad();

export default class extends Controller {
  connect() {
    microModal.init();

    observer.observe();
  }
}
