import { Controller } from '@hotwired/stimulus';
import cookies from 'js-cookie';

const messageDefaults = {
  valueMissing: 'Fyll i detta fält.',
  valueMissingCheckbox: 'Detta fält är obligatoriskt.',
  valueMissingRadio: 'Välj ett värde.',
  valueMissingSelect: 'Välj ett värde.',
  valueMissingSelectMulti: 'Välj åtminstone ett värde.',
  typeMismatchEmail: 'Fyll i en epost-adress.',
  typeMismatchURL: 'Fyll i en URL.',
  tooShort:
    'Please lengthen this text to {minLength} characters or more. You are currently using {length} characters.',
  tooLong:
    'Please shorten this text to no more than {maxLength} characters. You are currently using {length} characters.',
  patternMismatch: 'Please match the requested format.',
  badInput: 'Fyll i ett nummer.',
  stepMismatch: 'Please select a valid value.',
  rangeOverflow: 'Please select a value that is no more than {max}.',
  rangeUnderflow: 'Please select a value that is no less than {min}.',
  generic: 'The value you entered for this field is invalid.',
  multipleErrors:
    '{icon} Kontrollera dina uppgifter, {fields} fält behöver korrigeras',
  disableSubmit: 'Skickar...',
};

const icons = {
  warning:
    '<span class="icon icon-warning" role="presentation"><svg xmlns="http://www.w3.org/2000/svg"><use xlink:href="#icon-warning"></use></svg></span>',
};

const classNameDefaults = {
  inputError: 'input-error',
  fieldError: 'field-error',
};

export default class extends Controller {
  static targets = ['submit', 'field', 'formError', 'select'];

  connect() {
    // Add the `novalidate` attribute to the form
    this.addNoValidate();
  }

  /**
   * Add the `novalidate` attribute to the form
   */
  addNoValidate() {
    this.element.noValidate = true;
  }

  getErrorField(field) {
    let { id } = field;
    if (field.dataset.groupField === 'true') {
      id = field.name;
    }
    return field.form.querySelector(`#${id}-error`);
  }

  showError(field, error) {
    // Add input error class
    field.classList.add(classNameDefaults.inputError);

    // Add field error
    const errorField = this.getErrorField(field);
    errorField.innerHTML = error;
  }

  removeError(field) {
    // Remove input error class
    field.classList.remove(classNameDefaults.inputError);

    // Remove field error (set no-break space)
    const errorField = this.getErrorField(field);
    errorField.innerHTML = '\u00A0';
  }

  showOrRemoveError(field, returnError) {
    // Get possible error
    const error = this.getError(field);

    // If there's an error, show it
    if (error) {
      this.showError(field, error);
      if (returnError) {
        return error;
      }
      return;
    }

    // Otherwise, remove any errors that exist
    this.removeError(field);
  }

  validateField(event) {
    this.showOrRemoveError(event.target);
  }

  getError(field) {
    const { validity } = field;

    // If valid
    if (validity.valid) return null;

    // If field is required and empty
    if (validity.valueMissing) {
      if (field.type === 'checkbox') {
        return messageDefaults.valueMissingCheckbox;
      }

      if (field.type === 'radio') {
        return messageDefaults.valueMissingRadio;
      }

      if (field.type === 'select-multiple') {
        return messageDefaults.valueMissingSelectMulti;
      }

      if (field.type === 'select-one') {
        return messageDefaults.valueMissingSelect;
      }

      return messageDefaults.valueMissing;
    }

    // If not the right type
    if (validity.typeMismatch) {
      // Email
      if (field.type === 'email') {
        return messageDefaults.typeMismatchEmail;
      }

      // URL
      if (field.type === 'url') return messageDefaults.typeMismatchURL;
    }

    // If too short
    if (validity.tooShort) {
      return messageDefaults.tooShort
        .replace('{minLength}', field.getAttribute('minLength'))
        .replace('{length}', field.value.length);
    }

    // If too long
    if (validity.tooLong) {
      return messageDefaults.tooLong
        .replace('{minLength}', field.getAttribute('maxLength'))
        .replace('{length}', field.value.length);
    }

    // If number input isn't a number
    if (validity.badInput) return messageDefaults.badInput;

    // If a number value doesn't match the step interval
    if (validity.stepMismatch) return messageDefaults.stepMismatch;

    // If a number field is over the max
    if (validity.rangeOverflow) {
      return messageDefaults.rangeOverflow.replace(
        '{max}',
        field.getAttribute('max'),
      );
    }

    // If a number field is below the min
    if (validity.rangeUnderflow) {
      return messageDefaults.rangeUnderflow.replace(
        '{min}',
        field.getAttribute('min'),
      );
    }

    // If pattern doesn't match
    if (validity.patternMismatch) {
      // If pattern info is included, return custom error
      if (field.hasAttribute('title')) return field.getAttribute('title');

      // Otherwise, generic error
      return messageDefaults.patternMismatch;
    }

    // If all else fails, return a generic catchall error
    return messageDefaults.generic;
  }

  /**
   * Get fields that have errors
   *
   * @returns {Array}
   */
  getErrorFields() {
    const errorFields = [];
    this.fieldTargets.forEach((field) => {
      const error = this.showOrRemoveError(field, true);
      if (error) {
        errorFields.push(field);
      }
    });

    return errorFields;
  }

  /**
   * Post form
   */
  post() {
    const csrftoken = cookies.get('csrftoken');
    const form = new FormData();

    const serializableElements = Array.from(this.element.elements).filter(
      (element) => {
        if (
          element.nodeName === 'INPUT' &&
          (((element.type === 'radio' || element.type === 'checkbox') &&
            !element.checked) ||
            element.type === 'file')
        ) {
          return false;
        }
        return true;
      },
    );

    serializableElements.forEach((element) => {
      form.append(element.name, element.value);
    });

    fetch(this.element.action, {
      method: 'POST',
      body: form,
      credentials: 'same-origin',
      headers: {
        'X-REQUESTED-WITH': 'XMLHttpRequest',
        'X-CSRFTOKEN': csrftoken,
      },
    })
      .then((response) => response.text())
      .then((html) => {
        this.enableFields();
        this.element.innerHTML = html;
      });
  }

  disableFields() {
    this.disableSubmitButton();
    this.fieldTargets.forEach(this.disableField);
  }

  disableField(field) {
    // Assigning to a DOM property, not the parameter
    // eslint-disable-next-line no-param-reassign
    field.disabled = true;
  }

  disableSubmitButton() {
    // Disable submit button
    this.submitTarget.disabled = true;
    // Cache default button text
    this.submitTarget.dataset.defaultText = this.submitTarget.innerHTML;
    // Set disabled text
    this.submitTarget.innerHTML = messageDefaults.disableSubmit;
  }

  enableFields() {
    this.enableSubmitButton();
    this.fieldTargets.forEach(this.enableField);
  }

  enableField(field) {
    // Assigning to a DOM property, not the parameter
    // eslint-disable-next-line no-param-reassign
    field.disabled = false;
  }

  enableSubmitButton() {
    this.submitTarget.disabled = false;
    this.submitTarget.innerHTML = this.submitTarget.dataset.defaultText;
  }

  submit(event) {
    // Take control of form submission
    event.preventDefault();

    // Check for validity
    const errorFields = this.getErrorFields();
    if (errorFields.length) {
      // Show amount of errors
      this.formErrorTarget.innerHTML = messageDefaults.multipleErrors
        .replace('{fields}', errorFields.length)
        .replace('{icon}', icons.warning);

      // Focus on first error
      errorFields[0].focus();
      return;
    }

    // Disable fields
    this.disableFields();

    // Post form to backend if there are no errors
    this.post();
  }
}
