import { Controller } from '@hotwired/stimulus';

const selectedLinkClass = 'filter-box--selected';
const selectedTabClass = 'tab--selected';
const hiddenTabClass = 'tab--hidden';
const selectedFormClass = 'tab-panel--selected';
const hiddenFormClass = 'tab-panel--hidden';

export default class extends Controller {
  static targets = [
    'contactTab',
    'contactLink',
    'contactForm',
    'prospectTab',
    'prospectLink',
    'prospectForm',
    'consultationTab',
    'consultationLink',
    'consultationForm',
  ];

  setActive(activeTarget, hiddenTarget1, hiddenTarget2) {
    this[`${activeTarget}FormTarget`].classList.remove(hiddenFormClass);
    this[`${activeTarget}FormTarget`].classList.add(selectedFormClass);
    this[`${activeTarget}TabTarget`].classList.remove(hiddenTabClass);
    this[`${activeTarget}TabTarget`].classList.add(selectedTabClass);
    this[`${activeTarget}LinkTarget`].classList.add(selectedLinkClass);
    this[`${activeTarget}LinkTarget`].setAttribute('aria-selected', 'true');

    this[`${hiddenTarget1}FormTarget`].classList.remove(selectedFormClass);
    this[`${hiddenTarget1}FormTarget`].classList.add(hiddenFormClass);
    this[`${hiddenTarget1}TabTarget`].classList.remove(selectedTabClass);
    this[`${hiddenTarget1}TabTarget`].classList.add(hiddenTabClass);
    this[`${hiddenTarget1}LinkTarget`].classList.remove(selectedLinkClass);
    this[`${hiddenTarget1}LinkTarget`].setAttribute('aria-selected', 'false');

    this[`${hiddenTarget2}FormTarget`].classList.remove(selectedFormClass);
    this[`${hiddenTarget2}FormTarget`].classList.add(hiddenFormClass);
    this[`${hiddenTarget2}TabTarget`].classList.remove(selectedTabClass);
    this[`${hiddenTarget2}TabTarget`].classList.add(hiddenTabClass);
    this[`${hiddenTarget2}LinkTarget`].classList.remove(selectedLinkClass);
    this[`${hiddenTarget2}LinkTarget`].setAttribute('aria-selected', 'false');
  }

  selectContact(event) {
    event.preventDefault();
    this.setActive('contact', 'prospect', 'consultation');
  }

  selectProspect(event) {
    event.preventDefault();
    this.setActive('prospect', 'contact', 'consultation');
  }

  selectConsultation(event) {
    event.preventDefault();
    this.setActive('consultation', 'contact', 'prospect');
  }
}
