import { Controller } from '@hotwired/stimulus';
import cookies from 'js-cookie';

export default class extends Controller {
  input(event) {
    if (event.target.validity.valid) {
      // In case there is an error message visible, if the field
      // is valid, we remove the error message.
      const error = event.target.parentElement.querySelector('span');
      error.innerHTML = ''; // Reset the content of the message
      error.className = 'error'; // Reset the visual state of the message
    }
  }

  isAllValid() {
    const validFields = [];
    const fields = document.querySelectorAll('.form-field input');
    fields.forEach((field) => {
      if (!field.validity.valid) {
        const error =
          field.name === 'contact_type'
            ? document.querySelector('#id_contact_type + span')
            : field.parentElement.querySelector('span');
        error.innerHTML = 'Fältet är obligatoriskt';
        error.className = 'error active';
      } else {
        validFields.concat(field);
      }
    });
    return Boolean(validFields.length);
  }

  post() {
    const csrftoken = cookies.get('csrftoken');
    const form = new FormData(this.element);

    fetch(this.element.target, {
      method: 'POST',
      body: form,
      credentials: 'same-origin',
      headers: {
        'X-REQUESTED-WITH': 'XMLHttpRequest',
        'X-CSRFTOKEN': csrftoken,
      },
    })
      .then((response) => response.text())
      .then((html) => {
        this.element.innerHTML = html;
      });
  }

  submit(event) {
    event.preventDefault();

    if (this.isAllValid()) {
      this.post();
    }
  }
}
