import { Controller } from '@hotwired/stimulus';
import { debounce } from 'js-common';

const singleColWidth = 700;

export default class extends Controller {
  static targets = [
    'image',
    'primaryImage',
    'primaryList',
    'secondaryImage',
    'secondaryList',
    'allList',
  ];

  initialize() {
    this.checkWindowWidth();
    window.addEventListener('resize', debounce(this.handleResizeEnd, 200));
  }

  disconnect() {
    window.removeEventListener('resize');
  }

  listInOneColumn() {
    this.imageTargets.forEach((image) => {
      this.allListTarget.append(image);
    });
  }

  listInTwoColumns() {
    this.primaryImageTargets.forEach((image) => {
      this.primaryListTarget.append(image);
    });
    this.secondaryImageTargets.forEach((image) => {
      this.secondaryListTarget.append(image);
    });
  }

  checkWindowWidth = () => {
    if (global.innerWidth && global.innerWidth <= singleColWidth) {
      this.listInOneColumn();
    } else {
      this.listInTwoColumns();
    }
  };

  handleResizeEnd = () => {
    this.checkWindowWidth();
  };
}
