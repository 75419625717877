import { Application } from '@hotwired/stimulus';
import { definitionsFromContext } from '@hotwired/stimulus-webpack-helpers';

import {
  onReady,
  initElementController,
  Lightbox,
  ViewportHeight,
} from 'js-common';

// Initialize Stimulus
const application = Application.start();
const context = require.context('./controllers', true, /\.js$/);
application.load(definitionsFromContext(context));

// Initialize Lightbox from js-common manually since both js-common and Stimulus
// uses "data-controller"
const lightboxElements = document.querySelectorAll('#lightbox-common');

onReady(() => {
  lightboxElements.forEach((el) => {
    initElementController(Lightbox, el);
    initElementController(ViewportHeight, el);
  });
});
