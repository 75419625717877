import { Controller } from '@hotwired/stimulus';

// Controller for triggering lightbox with images

export default class extends Controller {
  static targets = ['lightboxLinksContainer'];

  triggerLightbox = (e) => {
    // Do not follow link action. Keep the element as an anchor tag if
    // JS is somehow disabled, the user will reach the image through the link.
    e.preventDefault();

    const href = e.currentTarget.getAttribute('href');

    // Retrive same image in lightbox container and create a click on the
    // correct element
    const lightboxLinksContainer = this.lightboxLinksContainerTarget;
    const lightboxElToClick = lightboxLinksContainer.querySelector(
      `a[href='${href}']`,
    );

    if (lightboxElToClick) {
      lightboxElToClick.click();
    }
  };

  // Create a click on first lightbox image to activate lightbox
  triggerFirstImage() {
    const firstImage =
      this.lightboxLinksContainerTarget.querySelectorAll('a')[0];
    if (firstImage) {
      firstImage.click();
    }
  }
}
